// import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#334158',
		},
		secondary: {
			main: '#D86272',
		},
		text: {
			primary: 'rgba(51,65,88,0.8)',
		},
		success: {
			main: '#62ac44',
		},
		background: {
			default: '#fdf7f7',
			paper: '#efefef',
		},
	},
	typography: {
		fontFamily: 'Oswald',
		letterSpacing: 'normal',
		h1: {
			fontWeight: 900,
			fontSize: '50px',
			lineHeight: '120%',
			fontFamily: 'Zilla Slab Highlight',
		},
		h2: {
			fontFamily: 'Zilla Slab Highlight',
		},
		h3: {
			fontFamily: 'Zilla Slab Highlight',
			fontWeight: 400,
			fontSize: '25px',
			marginBlockEnd: '1.875rem',
		},
		h4: {
			fontFamily: 'Oswald',
			fontWeight: 400,
			fontSize: '20px',
			marginBlockEnd: '1rem',
		},
		subtitle1: {
			fontFamily: 'Oswald',
		},
		p: {
			marginBlockEnd: '1rem',
		}
		
	},
	shape: {
		borderRadius: 4,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					padding: '12px 30px',
					borderRadius: 0
				},
			},
			variants: [
				{
					props: { variant: 'aleign' },
					style: {
						fontWeight: 700,
						fontSize: '12px',
						padding: '12px 40px',
						margin: '.3125rem 1px',
						lineHeight: '20px',
						letterSpacing: '.2em',
						border: '2px solid transparent',

						// outline: '1px solid transparent',
						// transition: 'all .5s ease .25s',

					},
				},
			],
		},
	}

});

export default theme;
